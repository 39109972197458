import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Placeholder from './Placeholder';

import * as styles from "./EventPreview.module.css";

export default function EventPreview({ event }) {
    return (
        <div className={styles.wrapper}>
            <Link
                to={`/${event.slug}`}
            >
                {event.frontmatter.image && event.frontmatter.image !== null ?
                    <GatsbyImage
                        image={event.frontmatter.image.childImageSharp.gatsbyImageData}
                        alt={event.frontmatter.title}
                        className={styles.thumbnail}
                    />
                :
                    <Placeholder className={styles.thumbnail} event />
                }
            </Link>

            <div className={styles.copy}>
                <h3 className={styles.title}>
                    <Link to={`/${event.slug}`}>
                        {event.frontmatter.isCancelled !== null && event.frontmatter.isCancelled === true && <span>CANCELLED: </span>}{event.frontmatter.title}
                    </Link>
                </h3>
                {event.frontmatter.isoStartDate && event.frontmatter.isoYear &&
                    <p>{event.frontmatter.isoStartDate}, {event.frontmatter.isoYear}</p>
                }

                {event.frontmatter.isoStartTime && event.frontmatter.isoEndTime &&
                    <p>{event.frontmatter.isoStartTime} - {event.frontmatter.isoEndTime}</p>
                }
                
                {event.frontmatter.locationName !== null && event.frontmatter.locationName.length > 0 &&
                    <p>{event.frontmatter.locationName}</p>
                }
            </div>
        </div>
    )
}