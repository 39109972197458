import React from "react";
import { graphql } from "gatsby";

import Layout from "../templates/Layout";
import EventPreview from "../components/preview/EventPreview";
import Corona from "../components/corona/Corona";


export const pageQuery = graphql`
    query EventIndexQuery {
        siteBuildMetadata {
            buildTime(formatString: "YYYY-MM-DD")
        }

        allMdx(
            filter: {fileAbsolutePath: {regex: "data/calendar/"}}
            sort: {fields: frontmatter___start, order: ASC}
        ){
            edges {
                node {
                    slug
                    frontmatter {
                        title
                        start
                        end
                        compareTime: start(formatString: "YYYY-MM-DD")
                        isoYear: start(formatString: "YYYY")
                        isoStartDate: start(formatString: "MMMM Do")
                        isoStartTime: start(formatString: "h:mmA")
                        isoEndTime: end(formatString: "h:mmA")
                        locationName
                        locationCoords
                        isCancelled
                        image {
                            childImageSharp {
                                gatsbyImageData(formats: JPG, width: 280)
                            }
                        }
                    }
                }
            }
        }
    }
`

class EventIndex extends React.Component {
    render() {
        const events = this.props.data.allMdx.edges;
        let newEvents = [];
        let pastEvents = [];

        const buildDate = Date.parse(this.props.data.siteBuildMetadata.buildTime);

        events.map(({ node }) => {
            let postDate = Date.parse(node.frontmatter.compareTime);

            if(postDate > buildDate) {
                newEvents.push(node);
            } else {
                pastEvents.push(node);
            }

            return({}); //return value expected, even though it doesn't do anything
        });

        return (
            <Layout
                title="Event calendar"
                location={this.props.location}
            >
                Nothing to display for now
                {/* <div>
                    <Corona />
                    {newEvents.length > 0 &&
                        <h3>Upcoming Events</h3>
                    }
                    {newEvents
                        .map(( node ) => {
                            return (
                                <div key={node.slug}>
                                    <EventPreview event={node} />
                                </div>
                            )
                        })
                    }

                    {pastEvents.length > 0 &&
                        <h3>Previous Events</h3>
                    }
                    {pastEvents
                        .map(( node ) => {
                            return (
                                <div key={node.slug}>
                                    <EventPreview event={node} />
                                </div>
                            )
                        })
                    }
                </div> */}
            </Layout>
        )
    }
}
        
export default EventIndex