import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

export default function Placeholder(props) {
    return (
        <StaticQuery
            query={graphql`query PlaceholderImage {
                green: file(relativePath: {eq: "placeholders/green.png"}){
                    childImageSharp {
                        gatsbyImageData (
                            transformOptions: {cropFocus: CENTER}
                            layout: CONSTRAINED
                        )
                    }
                }
                brown: file(relativePath: {eq: "placeholders/brown.png"}){
                    childImageSharp {
                        gatsbyImageData (
                            transformOptions: {cropFocus: CENTER}
                            layout: CONSTRAINED
                        )
                    }
                }
            }`}

            render={data => (
                props.event ?
                    <GatsbyImage className={props.className} image={data.green.childImageSharp.gatsbyImageData} />
                :
                    <GatsbyImage className={props.className} image={data.brown.childImageSharp.gatsbyImageData} />
            )}
        />
    );
}